(function(){
    $(document).ready(function(){
        "use strict";

        var cookieAlert = document.querySelector("#cookies-consent");
        var acceptCookies = $("#cookies-consent > .close");

        cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

        if (!getCookie("cookies-consent")) {
            cookieAlert.classList.add("show");
        }

        acceptCookies.on("click", function () {
            setCookie("cookies-consent", true, 60);
            cookieAlert.classList.remove("show");
        });
    });
})();

// Cookie functions stolen from w3schools
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}