window.renderYourProjectStatus = function(data){
  var rowTemplateFn = Handlebars.compile($('#your-project-status-row-template').remove().html());
  data.forEach(function(row){
    $('.your-project-status-table__tbody').append(rowTemplateFn(row));
  });

  $('.your-project-status-table__tr--header--expandable').on('click', function(){
    $(this).toggleClass('opened');
    $(this).next('.your-project-status-table__tr--content')
      .css('height', $(this).hasClass('opened') ? 'auto' : '0');
  });
}
