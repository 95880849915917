$(document).ready(function(){

  function setResponsiveImage(){
    if(window.innerWidth <= 767 ){
      $('.carousel-item img[data-desktop][data-mobile]').each(function(){
        $(this).attr('src', $(this).data('mobile'));
      });
    }else{
      $('.carousel-item img[data-desktop][data-mobile]').each(function(){
        $(this).attr('src', $(this).data('desktop'));
      });
    }
  }

  $('[href="#prize-rewards"]').on('click', function(e){
    e.preventDefault();
    $('html, body').animate({scrollTop: $('.rewards').offset().top - $('.navbar.fixed-top').height()}, 1000);
  });

  $(window).on('load resize orientationchange', setResponsiveImage);
  setTimeout(setResponsiveImage, 500);

});
