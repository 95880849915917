// data = Array({bu: string, name: string, creator: string, summary: string})
// note: input array expects `bu` field but here we call it "category" internally
window.renderApprovedIdeas = function(_data){
  // sory by bu alphabetical
  var data = _data.sort(function(a, b){
    return (a.bu == b.bu) ? 0 : ( (a.bu < b.bu) ? -1 : 1 );
  });

  // insert rows
  var rowTemplateFn = Handlebars.compile($('#approved-ideas-table-row-template').remove().html());
  data.forEach(function(row){
    $('.approved-ideas-table__tbody').append(rowTemplateFn(row));
  });

  // populate filter
  var uniqueCategories = [];
  data.forEach(function(row){
    if(uniqueCategories.indexOf(row.bu) === -1){
      uniqueCategories.push(row.bu);
    }
  });
  uniqueCategories.forEach(function(category){
    $('.filters').append(
      $('<span class="filter" />').text(category)
    );
    $('#mobile-select').append(
      $('<option />').text(category)
    );
  });

  // desktop filter (proxy to mobile filter)
  $('.filters > .filter').on('click', function(e){
    $('#mobile-select').val($(this).text()).trigger('change');
  });

  // mobile filter
  $('#mobile-select').on('change', function(e){
    $('.filters > .filter').removeClass('checked');
    $('.filters > .filter:contains("'+$(this).val()+'")').addClass('checked');

    $('.approved-ideas-table__tbody .approved-ideas-table__tr').hide();
    if($(this).val() == 'ALL'){
      $('.approved-ideas-table__tbody .approved-ideas-table__tr').show();
    }else{
      $('.approved-ideas-table__tbody .approved-ideas-table__tr[data-category="'+$(this).val()+'"]').show();
    }
  });

  // calculate __tr--first-of-category/__tr--second-of-category class, add __td--header
  var rollingCategory = '';
  var headerCells = $('.approved-ideas-table__thead .approved-ideas-table__td');
  $('.approved-ideas-table__tbody .approved-ideas-table__tr').each(function(i, row){
    var $row = $(row);
    var $cells = $(row).find('div');
    var category = $row.data('category');

    var firstRowInCategory = !(rollingCategory === category);
    rollingCategory = category;

    $row.addClass(firstRowInCategory ? 'approved-ideas-table__tr--first-of-category' : 'approved-ideas-table__tr--second-of-category');

    $cells.each(function(j, cell){
      var $cell = $(cell);
      $cell.before($(headerCells[j]).clone().addClass('approved-ideas-table__td approved-ideas-table__td--header'));
    });
  });
}