window.renderIdeaStatusSummary = function(_data){
  // sort by numberInProgress
  var data = _data.sort(function(a, b){
    return a.numberInProgress == b.numberInProgress ? 0 : (a.numberInProgress > b.numberInProgress ? -1 : 1);
  });

  // assume number in progress is always > completed
  var maxNumberOfProject = data[0].numberInProgress;

  // calculate step size, accepting up to 6 ticks, and ceil the step size to nearest multiple of 5 (6 is limitied by 320px screen)
  var stepSize = Math.ceil( (maxNumberOfProject / 6) / 5 ) * 5;
  var actualTicksNeeded = Math.ceil(maxNumberOfProject / stepSize);
  var yAxesMax = (actualTicksNeeded + 0.5) * stepSize; // aesthetics, to prevent the last tick getting too close to the edge

  // append row
  var rowTemplateFn = Handlebars.compile($('#bar-chart-row-template').remove().html());
  data.forEach(function(row){
    row.numberCompletedWidth = row.numberCompleted / yAxesMax * 100 + '%';
    row.numberInProgressWidth = row.numberInProgress / yAxesMax * 100 + '%';
    $('.bar-chart').append(rowTemplateFn(row));
  });

  // add y ticks
  var $headerBarZone = $('.bar-chart__row--header .bar-chart__row__bar-zone');
  for(var tickValue = 0; tickValue < yAxesMax; tickValue += stepSize){
    var style = 'left:' + (tickValue / yAxesMax * 100) + '%;';
    $headerBarZone.append('<div class="bar-chart__row__bar-zone__tick-mark" style="' + style + '">' + tickValue + '</div>');
  }
}
