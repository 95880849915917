// back to top
$(document).ready(function(){
    $('#mobile-menu').on('show.bs.collapse', function(){
        $('#mobile-menu').removeClass('hidden');
    });
    $('#mobile-menu').on('hide.bs.collapse', function () {
        $('#mobile-menu').addClass('hidden');
    });
    $('#mobile-menu').on('hidden.bs.collapse', function () {
        $('#mobile-menu').removeClass('hidden');
    });

    $(window).scroll(function(){
        if ($(this).scrollTop() > 200 ) {
            $('#back-to-top').fadeIn(200);
        } else {
            $('#back-to-top').fadeOut(200);
        }
    });

    $('#back-to-top').on('click', function(){
        $("html, body").animate({scrollTop: 0}, 1000);
    });

});

// sticky bottom
$(document).ready(function(){
    var doc = document.documentElement;
    var $stickyBottom = $('.sticky-bottom');
    var stickyBottom = $stickyBottom[0];
    var $stickyBottomInner = $stickyBottom.find('.sticky-bottom-inner');

    $(window).on('scroll resize', function sticky(){
        var top = window.pageYOffset || doc.scrollTop;
        var scrollTop = top + window.innerHeight;
        var stickyBottomInnerHeight = $stickyBottomInner.height();

        $stickyBottom.height(stickyBottomInnerHeight);
        if(scrollTop > (stickyBottom.offsetTop + stickyBottomInnerHeight)){
            $stickyBottom.removeClass('stick');
        }else{
            $stickyBottom.addClass('stick');
        }
    });
});