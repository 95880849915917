window.renderSuccessfulStories = function(data) {
  function ellipsizeTextBox(el, innerHeight, contentBox) {
    var wordArray = el.innerHTML.split(" ");
    var remainArray = [];
    while ($(innerHeight).outerHeight(true) + 44 > $(contentBox).height()) {
      remainArray.unshift(wordArray.pop());
      el.innerHTML =
        wordArray.join(" ") +
        '<span class="more-dots">...</span><span class="more-hidden"> ' +
        remainArray.join(" ") +
        "</span>";
      if (wordArray.length == 0) return;
    }
  }

  data.forEach(function(row, i){
    row.rowClass = (i % 2 == 0) ? 'blue-title white-text' : 'white-title blue-text';
  })

  var rowTemplateFn = Handlebars.compile($('#successful-story-row-template').remove().html());
  data.forEach(function(row){
    $('#successful-stories').append(rowTemplateFn(row));
  });

  $("body").on("click", ".more-button", function(e) {
    e.preventDefault();
    $(this)
      .closest(".content-box-inner")
      .find(".more-dots")
      .remove();
    $(this)
      .closest(".content-box-inner")
      .find(".more-hidden")
      .addClass("display");
    $(this).remove();
  });

  $(".content-box-inner-height").each(function(index, element) {
    var contentBoxInner = $(element).closest(".content-box-inner");
    var contentBox = $(element).closest(".content-box");
    var contentBoxInner_height = $(contentBoxInner).outerHeight(true);
    var contentBoxInnerHeight_height = $(element).outerHeight(true);
    if (contentBoxInnerHeight_height > contentBoxInner_height) {
      var divs = $(element)
        .find("div:not(.content-subtitle)")
        .get()
        .reverse();
      if (divs.length > 0) {
        ellipsizeTextBox(divs[0], element, contentBox[0]);
        $(divs[0]).addClass("more");
        $(divs[0]).append(
          '<button class="more-button"><span>More</span></button>'
        );
      }
    }
  });
}
